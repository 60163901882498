@import 'styles/includes.scss';

.Root {
    display: flex;
    flex-direction: column;
    position: relative;

    @include media(ml) {
        padding-left: 60px;
        padding-right: 60px;
    }

    @include media(l) {
        padding-left: 0;
        padding-right: 0;
    }

    @include media(ml) {
        flex-direction: row;
    }
}

.RootLinked {
    @include media(ml) {
        &:hover .ImageContainer {
            box-shadow: 24px 24px 0 0 $color-light-pink;
        }
    }
}

.ImageLeft {
    @include media(ml) {
        flex-direction: row-reverse;
    }
}

.SrOnly {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
    text-transform: none;
}

.Link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Content {
    display: flex;
    flex-direction: column;
    justify-self: start;

    order: 2;

    @include media(ml) {
        flex-shrink: 0;
        max-width: 488px;
        padding-right: 80px;
        justify-content: center;

        order: 1;
        .ImageLeft & {
            padding-left: 80px;
            padding-right: 0;
        }
    }

    @include media(xl) {
        padding-right: 144px;
        .ImageLeft & {
            padding-left: 144px;
            padding-right: 0;
        }
    }
}

.Tag {
    @include small-labels;
    text-transform: uppercase;
    padding-bottom: 8px;
}

.Title {
    @include h3-headline;
    padding-bottom: 24px;
}

.Text {
    @include body-copy;
}

.ImageLeft {
    position: relative;
}

.ImageContainer {
    order: 1;
    margin-bottom: 24px;
    transition: box-shadow 0.2s ease-out;

    @include media(ml) {
        max-width: 830px;
        height: 496px;

        order: 2;
    }
}

.Image {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;

    margin-bottom: 24px;

    @include media(ml) {
        height: 100%;
        margin-bottom: 0;
    }
}
